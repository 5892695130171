<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-3 col-6">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Select Mill" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="originModel" :items="originLists" default="" item-value="origin_id" item-text="descr" label="Select Origin" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="materialModel" :items="materialLists" default="" item-value="prod_group" item-text="descr" label="Select Material" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-3 col-6">
                                <v-select v-model="brandModel" :items="brandLists" default="" item-value="brand_id" item-text="descr" label="Select Brand" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="thicknessModel" :items="thicknessLists" default="" item-value="thick" item-text="thick" label="Select Thickness" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="azModel" :items="azLists" default="" item-value="coating_mass" item-text="coating_mass" label="Select Coating Mass" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-select v-model="gradeModel" :items="gradeLists" default="" item-value="grade_id" item-text="grade_id" label="Select Grade" clearable dense prepend-icon="mdi-checkbox-multiple-blank"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-12">
                                <v-btn class="mr-4" color="primary" elevation="2" small rounded @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'GBRK',
                disabled: false,
                href: '/admin/gbrk',
                },
                {
                text: 'GBRK Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Average Weight Meter',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            originModel: '',
            originLists: [],
            materialModel: '',
            materialLists: [],
            brandModel: '',
            brandLists: [],
            thicknessModel: '',
            thicknessLists: [],
            azModel: '',
            azLists: [],
            gradeModel: '',
            gradeLists: [],
            dateFrom: '',
            modalDateFrom: false,
            dateTo: '',
            modalDateTo: false
        }
    },
    async mounted(){
        
        this.initialize()

    },
    methods:{
        
        async initialize(){
            this.$store.dispatch('setOverlay', true)

            // this.dateFrom = this.currentPeriod()
            // this.dateTo = this.currentPeriod()

            document.querySelector(".cardGraph").style.display = "none"
            await axios.get(`${process.env.VUE_APP_URL}/api/gbrk/AverageWeightMeter`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.millLists = res.data.mill
                this.originLists = res.data.origin
                this.materialLists = res.data.material
                this.brandLists = res.data.brand
                this.thicknessLists = res.data.thickness
                this.azLists = res.data.az
                this.gradeLists = res.data.grade
                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async getChartData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'error',
                    })
                return false
            }

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph").style.display = "none"

            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/gbrk/AverageWeightMeter/getChartData`, { 

                    mill: this.millModel ? this.millModel : '',
                    origin: this.originModel ? this.originModel : '',
                    material: this.materialModel ? this.divisionModel : '',
                    brand: this.brandModel ? this.brandModel : '',
                    thickness: this.thicknessModel ? this.thicknessModel : '',
                    az: this.azModel ? this.azModel : '',
                    grade: this.gradeModel ? this.gradeModel : '',
                    dateFrom: this.dateFromFormatted ? this.dateFromFormatted : '',
                    dateTo: this.dateToFormatted ? this.dateToFormatted : '',

                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                if(res.data.arr_list1.length != 0){
                    document.querySelector(".cardGraph").style.display = "block"
                    this.renderChart1(res.data.arr_list1, res.data.min, res.data.max)
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    document.querySelector(".cardGraph").style.display = "none"
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }
                
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/')
                }

            })

        },

        renderChart1(data1, min, max){

            var chart = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                exportEnabled: false,
                theme: "light2",
                title: {
                    text: "Average Weight / Meter Analysis",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Average Weight / Meter",
                    labelFormatter: this.addSymbols,
                    minimum: min,
                    maximum: max
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        name: "Average",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelPlacement: "inside",
                        indexLabelOrientation: "vertical",
                        indexLabelFontColor: "#fff",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        color: "#00b7c2",
                        dataPoints: data1
                    }
                ]
            })

            chart.render()

        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        currentPeriod() {
            const current = new Date();
            const date = `${current.getFullYear()}-${current.getMonth()+1}`;      
            return date;
        }

    },

    

    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>